@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
